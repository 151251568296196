<template>
  <v-container fluid>
    <v-card flat class="mt-10 mx-auto" width="500px">
    <form @submit.prevent="userSignIn">
      <v-row dense>
        <v-col cols="12">
          <v-alert type="error" dismissible v-model="alert">
            {{ error }}
          </v-alert>
        </v-col>

        <v-col cols="12" align-self="center">
          <v-text-field outlined
            name="email"
            label="Email"
            id="email"
            v-model="email"
            required></v-text-field>
        </v-col>
        <v-col v-show="!resetProcess" cols="12">
          <v-text-field outlined
            name="password"
            label="Password"
            id="password"
            type="password"
            v-model="password"
            required></v-text-field>
        </v-col>

          <v-row v-if="!resetProcess">
            <v-col v-show="!resetProcess" cols="12" class="text-center mb-10">
              <v-btn :loading="loading" large :disabled="loading" color="primary" type="submit">Sign In</v-btn>
            </v-col>
            <v-col cols="12" class="text-center">
              <v-btn v-show="!loading" small outlined light :loading="loading" @click="resetProcess=true">Reset Password</v-btn>
            </v-col>
          </v-row>

          <v-row v-if="resetProcess">
            <v-col cols="12" class="text-center">
              <v-btn small outlined light :loading="loading" @click.prevent="onResetPassword">Reset Password</v-btn>
            </v-col>
            <v-col cols="12" class="text-center">
              <v-btn small outlined light :loading="loading" @click="resetProcess=false">Cancel</v-btn>
            </v-col>
          </v-row>


 

        <v-dialog v-model="passwordResetEmailSent" persistent max-width="290">
        <v-card>
          <v-card-title primary-title> 
            Check your email
          </v-card-title>
          <v-card-text>
            An email has been sent to you from no-reply@botls.app. 
            Click the link in the email to complete the password reset process.
            If you do not receive an email, please check your spam folder.
          </v-card-text>
          <v-card-actions>
            <v-btn outlined color="primary" @click="passwordResetEmailSent = false">Ok</v-btn>
          </v-card-actions>
        </v-card>
        </v-dialog>

      </v-row>
    </form>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import api from '@/store/api'
export default {
  data () {
    return {
      email: '',
      password: '',
      alert: false,
      passwordResetEmailSent: false,
      resetProcess: false,
    }
  },
  methods: {
    async userSignIn () {
      let email2 = this.email
      if(!email2.includes("@"))
        email2 = email2 + "@wrenhousebrewing.com"
      this.$store.dispatch('userSignIn', { email: email2, password: this.password })
    },
    onResetPassword () {
      if (this.email === '') {
        return this.$store.commit('setError', 'Enter your email')
      }
      this.$store.commit('setLoading', true)
      api.resetPassword(this.email)
      .then( () => {
        this.$store.commit('setLoading', false)
        this.passwordResetEmailSent = true
        this.resetProcess = false
      })
      .catch ( error => {
        this.resetProcess = false
        this.$store.commit('setLoading', false)
        console.log(error)
        //return this.$store.commit('setError', error)
      })
    },
  },
  computed: {
    ...mapGetters ([
      'loading'
    ]),
    error () {
      return this.$store.state.error
    },
  },
  watch: {
    error (value) {
      if (value) {
        this.alert = true
      }
    },
    alert (value) {
      if (!value) {
        this.$store.commit('setError', null)
      }
    }
  }
}
</script>